import React, { useState, useEffect } from "react"
import Layout from "@/components/layout"
// import SEO from "@/components/seo"
import style from './index.module.less'
import useNavigate from '@/hooks/useNavigate'


function Index(props) {
    useEffect(()=>{
        useNavigate('/')
    },[])

    return (
            <></>
    )
}

export default Index